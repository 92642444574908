"use client";

import { useState, useTransition } from "react";
import { Star } from "lucide-react";
import { submitFeedback } from "@/app/lib/actions";

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isPending, startTransition] = useTransition();

  const defaultMessages = [
    "I love this app! My favorite part is...",
    "I hope you can improve...",
    "It would be great if you could add...",
    "I'm having trouble with...",
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("message", message);
    formData.append("rating", rating.toString());

    startTransition(async () => {
      const result = await submitFeedback(formData);
      if (result.success) {
        setSubmitted(true);
        setEmail("");
        setMessage("");
        setRating(0);
      } else {
        // Handle error
        console.error(result.error);
      }
    });
  };

  const handleClose = () => {
    setSubmitted(false);
    onClose();
  };

  const handleRating = (value: number) => {
    setRating(value);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white p-4 sm:p-6 rounded-lg w-full max-w-md max-h-[90vh] overflow-y-auto">
        {!submitted ? (
          <>
            <h2 className="text-xl sm:text-2xl font-bold mb-4">
              Send Feedback
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border rounded text-sm"
                required
              />
              <div className="flex items-center justify-center space-x-1 mb-2">
                {[1, 2, 3, 4, 5].map((value) => (
                  <Star
                    key={value}
                    size={24}
                    onClick={() => handleRating(value)}
                    className={`cursor-pointer ${
                      value <= rating
                        ? "text-yellow-400 fill-current"
                        : "text-gray-300"
                    }`}
                  />
                ))}
              </div>
              <div className="space-y-2">
                {defaultMessages.map((msg, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={() => setMessage(msg)}
                    className="w-full text-left p-2 text-sm bg-gray-100 hover:bg-gray-200 rounded"
                  >
                    {msg}
                  </button>
                ))}
              </div>
              <textarea
                placeholder="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full p-2 border rounded text-sm h-32"
                required
              />
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={handleClose}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded text-sm"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isPending}
                  className="bg-blue-600 text-white px-4 py-2 rounded text-sm"
                >
                  {isPending ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="text-center">
            <h2 className="text-xl sm:text-2xl font-bold mb-4">Thank You!</h2>
            <p className="mb-4">
              Your feedback has been received. We appreciate your input!
            </p>
            <button
              onClick={handleClose}
              className="bg-blue-600 text-white px-4 py-2 rounded text-sm"
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackModal;
