"use client";
import { GeistSans } from "geist/font/sans";
import "./globals.css";
import { Analytics } from "@vercel/analytics/react";
import GoogleAnalytics from "@/components/GoogleAnalytics";
import Navbar from "@/components/navbar";
import "dotenv/config";
import { KindeProvider } from "@kinde-oss/kinde-auth-nextjs";
import { SpeedInsights } from "@vercel/speed-insights/next";

export default async function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="en" className={GeistSans.className}>
      <GoogleAnalytics />
      <meta
        name="google-site-verification"
        content="XnB-Z6B352eIm53VderjnsMPSSBIjzP9I_0dQeHzj-U"
      />

      <body className="bg-background text-foreground bg-gray-100 flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow container mx-auto py-2 md:py-8 md:px-4">
          <KindeProvider>
            {children}
            <SpeedInsights />
          </KindeProvider>
          <Analytics mode={"production"} />
        </main>
      </body>
    </html>
  );
}
