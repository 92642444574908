"use client";
import Link from "next/link";
import { useState } from "react";
import FeedbackModal from "./feedback-modal";
import { LoginLink, LogoutLink } from "@kinde-oss/kinde-auth-nextjs/components";
import { useKindeAuth } from "@kinde-oss/kinde-auth-nextjs";

const Navbar = () => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const navItems = [
    { label: "Dashboard", action: () => window.open("/", "_blank") },
    { label: "Historical Data", action: () => window.open("/stat", "_blank") },
    { label: "Feedback", action: () => setIsFeedbackModalOpen(true) },
  ];

  return (
    <nav className="bg-blue-600 p-2 sticky top-0 z-50">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <Link
            href="/"
            className="text-white text-xl md:text-2xl font-bold whitespace-nowrap text-center md:text-left mb-2 md:mb-0"
          >
            US Visa Appointments
          </Link>
          <div className="flex items-center justify-center md:justify-end space-x-1 md:space-x-2">
            {navItems.map((item, index) => (
              <button
                key={index}
                className="bg-white text-blue-600 px-2 py-1 rounded hover:bg-blue-100 transition-colors text-xs md:text-sm whitespace-nowrap"
                onClick={item.action}
              >
                {item.label}
              </button>
            ))}
            <LoginLink
              postLoginRedirectURL="/app"
              className="bg-white text-blue-600 px-2 py-1 rounded hover:bg-blue-100 transition-colors text-xs md:text-sm whitespace-nowrap"
            >
              Free Alert
            </LoginLink>
          </div>
        </div>
      </div>
      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
      />
    </nav>
  );
};

export default Navbar;
